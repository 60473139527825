import helpers from "@/helpers/helper";
import Axios from "./Axios";

const API_URL = helpers.API_ENDPOINT;

class emailService {
  getEmails(data) {
    return Axios({
      method  : "POST",
      url     : API_URL + 'company-emails/' + data.company_id + '?page=' + data.page,
      data    : data,
    });
  }
  postEmail(data) { 
    return Axios({
      method  : "POST",
      url     : API_URL + "emails",
      data    : data,
    });
  }
}
  
export default new emailService();