import clientService from "@/services/client.service";

export const client = {
    namespaced      : true,
    state   : {
        pipelines   : {},
        stages      : [],
        clients     : {},
        meta        : {},
        meta_p      : {}
    },
    actions : {
        getClient({ commit }, data) {
            return clientService.getClient(data).then((response) => {
                commit("clients", response.data);
                return Promise.resolve(response);
            });
        },
        getPipeline({ commit }, data) {
            return clientService.getPipeline(data).then((response) => {
                commit("pipelines", response.data);
                return Promise.resolve(response);
            });
        },
        getStages({ commit }) {
            return clientService.getStages().then((response) => {
                commit("stages", response.data.data);
                return Promise.resolve(response);
            });
        },
        getDeal({ commit }, id) {
            return clientService.getDeal(id).then((response) => {
                commit("deal", response.data);
                return Promise.resolve(response);
            });
        },
        patchClient({ commit }, data) {
            return clientService.patchClient(data).then((response) => {
                commit("deal", response.data);
                return Promise.resolve(response);
            });
        },
        deleteDeal({ commit }, data) {
            return clientService.deleteDeal(data).then((response) => {
                commit("deleteDeal", response.data);
                return Promise.resolve(response);
            });
        }
    },
    mutations: {
        clients(state, data) {
            state.clients   = data.data;
            state.meta      = data.meta;
        },
        pipelines(state, data) {
            state.pipelines = data.data;
            state.meta_p    = data.meta;
        },
        stages(state, data) {
            state.stages = data;
        },
        deal(state, data) {
            state.deal = data
        },
        deleteDeal(state, data) {
            for (let index = 0; index < (state.clients).length; index++) {
                if(state.clients[index].id == data.id) {
                    state.clients.splice(index,1)
                    return;
                }
            }
        }
    },
};