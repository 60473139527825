import { createStore } from 'vuex';
import { auth } from "./auth";
import { client } from './client';
import { task } from './task';
import { team } from './team';
import { notes } from './notes';
import { email } from './email';
import { zemo } from './zemo';
import { prospects } from './prospects';
import { activity } from './activity.module';
import { affiliate } from './affiliate';
import { interested } from './interested';
import { contact_us } from './contactus';

const store = createStore({
  modules: {
    auth,
    client,
    task,
    team,
    notes,
    email,
    zemo,
    prospects,
    activity,
    affiliate,
    interested,
    contact_us,
  }
})

export default store;
