import notesService from "@/services/notes.service";

export const notes = {
    namespaced  : true,
    state   : {
        notes   : [],
        note    : {},
        meta    : []
    },
    actions : {
        getNotes({ commit }, data) {
            return notesService.getNotes(data).then((response) => {
                commit("notes", response.data);
                return Promise.resolve(response);
            });
        },
        postNote({ commit }, data) {
            return notesService.postNote(data).then((response) => {
                commit("note", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchNotes({ commit }, data) {
            return notesService.patchNote(data).then((response) => {
                commit("note", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteNotes({ commit }, data) {
            return notesService.deleteNote(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations   : {
        notes(state, notes) {
            state.notes = notes.data;
            state.meta  = notes.meta
        },
        note(state, data) {
            for (let index = 0; index < (state.notes).length; index++) {
                if(state.notes[index].id == data.id) {
                    state.notes.splice(index,1,data);
                    state.note = data;
                    return;
                }
            }
            (state.notes).unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.notes).length; index++) {
                if(state.notes[index].id == data.id) {
                    state.notes.splice(index,1)
                    return;
                }
            }
        }
    },
};