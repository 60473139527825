import helpers from '@/helpers/helper';
import Axios from './Axios';

const API_URL = helpers.API_ENDPOINT;

class taskService {
    getAllTasks(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'all-tasks',
        data    : data
      });
    }
    getTask(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'company-tasks',
        data    : data,
      });
    }
    getTasks(data) {
      return Axios({
        method  : 'POST',
        url     : API_URL + 'company-tasks/' + data.company_id + '?page=' + data.page,
        data    : data,
      });
    }
    postEmail(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'tasks',
        data    : data,
      });
    }
    postTask(data) { 
      return Axios({
        method  : 'POST',
        url     : API_URL + 'tasks',
        data    : data,
      });
    }
    patchTask(data) { 
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'tasks/' + data.id,
        data    : data,
      });
    }
    deleteTask(data) { 
      return Axios({
        method  : 'DELETE',
        url     : API_URL + 'tasks/' + data.id,
      });
    }
  }
export default new taskService();