import taskService from "@/services/task.service";

export const task = {
    namespaced  : true,
    state       : {
        tasks   : [],
        task    : {},
        meta    : [],
    },
    actions : {
        getAllTasks({ commit }, data) {
            return taskService.getAllTasks(data).then((response) => {
                commit("allTasks", response.data);
                return Promise.resolve(response);
            });
        },
        getTasks({ commit }, data) {
            return taskService.getTasks(data).then((response) => {
                commit("tasks", response.data);
                return Promise.resolve(response);
            });
        },
        postTask({ commit }, data) {
            return taskService.postTask(data).then((response) => {
                commit("task", response.data.data);
                return Promise.resolve(response);
            });
        },
        patchTask({ commit }, data) {
            return taskService.patchTask(data).then((response) => {
                commit("task", response.data.data);
                return Promise.resolve(response);
            });
        },
        deleteTask({ commit }, data) {
            return taskService.deleteTask(data).then((response) => {
                commit("delete", data);
                return Promise.resolve(response);
            });
        },
    },
    mutations: {
        allTasks(state, data) {
            state.allTasks  = data.data
            state.meta      = data.meta
        },
        tasks(state, data) {
            state.tasks = data.data;
            state.meta_x = data.meta;
        },
        task(state, data) {
            for (let index = 0; index < (state.tasks).length; index++) {
                if(state.tasks[index].id == data.id) {
                    state.tasks.splice(index,1,data);
                    state.task = data;
                    return;
                }
            }
            state.tasks.unshift(data);
        },
        delete(state, data) {
            for (let index = 0; index < (state.tasks).length; index++) {
                if(state.tasks[index].id == data.id) {
                    state.tasks.splice(index,1)
                    return;
                }
            }
        }
    },
};