import axios from "axios";
import { authHeader } from "./auth.header";
import helpers from "@/helpers/helper";

const API_URL = helpers.API_ENDPOINT;

class ActivityService {
    getCategory(data) {
        var page = data ? data.page : 1;
        if (data.select) {
            return axios.get(API_URL + "blog/category" + "?page=" + page + '&size=' + data.size, { headers: authHeader(), }).then((response) => {
                return response.data;
            });
        } else {
            return axios.get(API_URL + "blog/category", { headers: authHeader(), }).then((response) => {
                return response.data;
            });
        }
    }
    getBlog(data) {
        var page = data ? data.page : 1;
        return axios.get(API_URL + "blog/posts" + "?page=" + page, { headers: authHeader(), }).then((response) => {
            return response.data;
        });
    }
}

export default new ActivityService();