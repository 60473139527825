import helpers from '@/helpers/helper';
import Axios from './Axios';

const API_URL = helpers.API_ENDPOINT;

class notesService {
  getNotes(data) {
    return Axios({
      method  : 'POST',
      url     : API_URL + 'company-notes/' + data.company_id + '?page=' + data.page,
      data    : data,
    });
  }
  postNote(data) { 
    return Axios({
      method  : 'POST',
      url     : API_URL + 'notes',
      data    : data,
    });
  }
  patchNote(data) { 
    return Axios({
      method  : 'PATCH',
      url     : API_URL + 'notes/' + data.id,
      data    : data,
    });
  }
  deleteNote(data) { 
    return Axios({
      method  : 'DELETE',
      url     : API_URL + 'notes/' + data.id,
    });
  }
}
  
  export default new notesService();