<template>
  <div class="main-wrapper">
    <div class="header header-one">
      <div class="header-left header-left-one" style="padding-right: 25px">
        <router-link to="/market/client" class="logo">
          <img src="/assets/img/logo.png" alt="Logo" />
        </router-link>
        <router-link to="/market/client" class="white-logo">
          <img src="/assets/img/logo.png" alt="Logo" />
        </router-link>
        <router-link to="/market/client" class="logo logo-small">
          <img src="/assets/img/logo.png" alt="" width="30" height="30" />
        </router-link>
      </div>

      <a href="#" @click="draw" id="toggle_btn" style="padding-left: 25px">
        <i class="fas fa-bars"></i>
      </a>
      <a class="mobile_btn" id="mobile_btn">
        <i class="fas fa-bars"></i>
      </a>
      <a href="#" @click="draw" id="toggle_btn" style="padding-left: 25px">
        <span style="font-size: 15px" class="day-message"></span>
        <span style="font-size: 15px">,</span>
      </a>

      <ul class="nav nav-tabs user-menu">
        <!-- ---------------- -->
        <li class="nav-item dropdown has-arrow main-drop">
          <a
            href="#"
            class="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <span
              class="user-img"
              v-if="user.attributes.profile_photo != undefined"
            >
              <img :src="user.attributes.profile_photo" alt="" />
              <span class="status online"></span>
            </span>
            <el-avatar v-else class="me-1">
              {{ $helpers.getInitials(user.attributes.name) }}
            </el-avatar>
            <span>{{ user.attributes.name.split("")[0] }}</span>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#" @click.prevent="Logout"
              ><i data-feather="log-out" class="me-1"></i> Sign Out</a
            >
          </div>
        </li>
      </ul>
    </div>

    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="menu-title">
              <span>CRM</span>            </li>

            <li
              :class="isActive == 'Deals' ? 'active' : ''"
              @click="setClass('Deals')"
            >
              <router-link to="/market/client"
                ><i data-feather="menu"></i> <span>Deals</span></router-link
              >
            </li>
            <li
              :class="isActive == 'Tasks' ? 'active' : ''"
              @click="setClass('Tasks')"
            >
              <router-link to="/task/tasks"
                ><i data-feather="list"></i> <span>Tasks</span></router-link
              >
            </li>
            <li
              :class="isActive == 'Team' ? 'active' : ''"
              @click="setClass('Team')"
            >
              <router-link to="/team/teams"
                ><i data-feather="users"></i> <span>Team</span></router-link
              >
            </li>
            <li
              :class="isActive == 'Statistics' ? 'active' : ''"
              @click="setClass('Statistics')"
            >
              <router-link to="/statistics/statistics"
                ><i data-feather="sliders"></i> <span>Statistics</span></router-link
              >
            </li>

























            
            <li class="menu-title">
              <span>Affiliate</span>
            </li>
           
            <li
              :class="isActive == 'Marketers' ? 'active' : ''"
              @click="setClass('Marketers')"
            >
              <router-link to="/affiliates"
                ><i data-feather="users"></i> <span>Marketers</span></router-link
              >
            </li>
            <li
              :class="isActive == 'Business' ? 'active' : ''"
              @click="setClass('Business')"
            >
              <router-link to="/affiliate-deals"
                ><i data-feather="list"></i> <span>Business</span></router-link
              >
            </li>
            <li class="menu-title">
              <span>Website</span>
            </li>
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
            >
              <a href="#"
                ><i data-feather="inbox"></i>
                <span>Visitors</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/interested"
                    :class="isSubmenuActive == 'Interested' ? 'active' : ''"
                    @click="setSubmenuClass('Interested')"
                    >Interested</router-link
                  >
                </li>                
                <li>
                  <router-link
                    to="/contact-us"
                    :class="isSubmenuActive == 'Contact Us' ? 'active' : ''"
                    @click="setSubmenuClass('Contact Us')"
                    >Contact Us</router-link
                  >
                </li>
              </ul>
            </li>
            <li
              :class="
                isActive == 'Partners'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Partners control')"
            >
              <a href="#"
                ><i data-feather="folder"></i>
                <span>Partners</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                      
                <li>
                  <router-link
                    to="/partner/profiles"
                    :class="isSubmenuActive == 'Partner Profiles' ? 'active' : ''"
                    @click="setSubmenuClass('Partner Profiles')"
                    >Partners</router-link
                  >
                </li>          
                <li>
                  <router-link
                    to="/partners-referrals"
                    :class="isSubmenuActive == 'Contacts' ? 'active' : ''"
                    @click="setSubmenuClass('Contacts')"
                    >Referrals</router-link
                  >
                </li>
              </ul>
            </li>
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
            >
              <a href="#"
                ><i data-feather="list"></i>
                <span>Blogs</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/blog/manage/blog"
                    :class="isSubmenuActive == 'Post' ? 'active' : ''"
                    @click="setSubmenuClass('Post')"
                    >Post</router-link
                  >
                </li>                
                <li>
                  <router-link
                    to="/blog/category"
                    :class="isSubmenuActive == 'Category' ? 'active' : ''"
                    @click="setSubmenuClass('Category')"
                    >Category</router-link
                  >
                </li>
              </ul>
            </li>
            <li
              :class="isActive == 'Testimonials' ? 'active' : ''"
              @click="setClass('Testimonials')"
            >
              <router-link to="/testimonials"
                ><i data-feather="edit"></i> <span>Testimonials</span></router-link
              >
            </li>

























            <li class="menu-title">
              <span>Sales</span>
            </li>
            <li
              :class="
                isActive == 'Performance Insights'
                  ? 'submenu active'
                  : 'submenu'
              "
              @click="setClass('Performance Insights control')"
            >
              <a href="#"
                ><i data-feather="calendar"></i>
                <span>Performance Insights</span>
                <span class="menu-arrow"></span
              ></a>
              <ul>
                <li>
                  <router-link
                    to="/sales/sales"
                    :class="isSubmenuActive == 'Sales' ? 'active' : ''"
                    @click="setSubmenuClass('Sales')"
                    >Sales</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/sales/account_values"
                    :class="isSubmenuActive == 'Account Value' ? 'active' : ''"
                    @click="setSubmenuClass('Account Value')"
                    >Account Value</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/sales/sales_projection"
                    :class="
                      isSubmenuActive == 'Sales Projection' ? 'active' : ''
                    "
                    @click="setSubmenuClass('Sales Projection')"
                    >Projection</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/sales/email_metrics"
                    :class="isSubmenuActive == 'Email Metrics' ? 'active' : ''"
                    @click="setSubmenuClass('Email Metrics')"
                    >Emails</router-link
                  >
                </li>
              </ul>
            </li>



            
          </ul>
        </div>
      </div>
    </div>
    <div class="page-wrapper">
      <div class="content container-fluid">
        <el-skeleton v-if="loading" :rows="15" animated />
        <router-view v-else></router-view>
      </div>
    </div>

    <el-dialog
      v-model="warningZone"
      title="Session Timeout"
      width="30%"
      :before-close="handleClose"
    >
      <p>
        You have been inactive for the passed 14 minutes! Your session expires
        in a minutes, if no action taken.
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" plain @click="Logout">Sign Out</el-button>
          <el-button type="primary" plain @click="this.warningZone = false"
            >Continue</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: "",
      loading: false,
      isActive: "Deals",
      isSubmenuActive: null,
      filteredCompany: [],
      events: ["click", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user ? this.$store.state.auth.user : {};
    },
  },
  created() {
    // this.draw();
    var scripts = ["/assets/js/script.js"];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
    Number.prototype.leadingZeroes = function (len) {
      return (new Array(len).fill("0", 0).join("") + this).slice(
        -Math.abs(len)
      );
    };
    this.greetings();
  },
  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  destroyed() {
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
  },
  methods: {
    setClass(active) {
      this.isActive = active;
    },
    setSubmenuClass(active) {
      this.isSubmenuActive = active;
    },
    Logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signin");
      window.location.reload();
    },
    setTimers() {
      localStorage.setItem("_warningtime", 14 * 60 * 1000);
      const warningTimer = parseInt(localStorage.getItem("_warningtime"));
      this.warningTimer = setTimeout(this.warningMessage, warningTimer);
      localStorage.setItem("_logouttime", 15 * 60 * 1000);
      const logoutTimer = parseInt(localStorage.getItem("_logouttime"));
      this.logoutTimer = setTimeout(this.Logout, logoutTimer);
    },
    warningMessage() {
      this.warningZone = true;
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    draw() {
      if ($("body").hasClass("mini-sidebar")) {
        $("body").removeClass("mini-sidebar");
        $(".subdrop + ul").slideDown();
      } else {
        $("body").addClass("mini-sidebar");
        $(".subdrop + ul").slideUp();
      }
      setTimeout(function () {}, 300);
      return false;
    },
    greetings() {
      $(document).ready(function () {
        function dateTime() {
          var ndate = new Date();
          var hours = ndate.getHours();
          var message =
            hours < 12
              ? "Good Morning"
              : hours < 18
              ? "Good Afternoon"
              : "Good Evening";
          $("span.day-message").text(message);
        }
        setInterval(dateTime, 1000);
      });
    },
    handleClose() {
      this.warningZone = false;
    },
  },
};
</script>