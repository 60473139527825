<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark"
          src="/assets/img/logo.png"
          alt="Logo"
        />
        <el-card class="loginbox">
          <el-form
            :model="user"
            size="large"
            ref="user"
            label-position="top"
            inline
            status-icon
          >
            <el-row class="p-3">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="text-center p-1">
                <h5>Forgot Password</h5>
              </el-col>
              <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <img
                  class="img-fluid logo-dark"
                  src="/assets/img/logo.png"
                  alt="Logo"
                />
                <div class="sign-in">
                  <span class="or-line"></span>
                  <span class="span-forgot">Forgot Password</span>
                </div>
              </el-col> -->
              <el-col style="margin-top: 10px" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item
                  prop="email"
                  :rules="[
                    {
                      required: true,
                      message: 'Email address is required',
                      trigger: ['blur'],
                    },
                    {
                      type: 'email',
                      message: 'Please input correct email address',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <el-input
                    v-model="user.email"
                    type="email"
                    autocomplete="off"
                    placeholder="Enter email address"
                  >
                    <template #prepend>
                      <span class="text-primary me-1" style="min-width: 30px:"
                        ><i class="fa fa-envelope"></i
                      ></span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col style="margin-top : 10px" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="">
                  <el-button
                    type="primary"
                    class="w-100"
                    @click="handleSendLink('user')"
                    :loading="loading"
                    >Reset Password</el-button
                  ></el-form-item
                >
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or">or</span>
                </div>
                <div class="text-center dont-have">
                  Already have an account yet?
                  <router-link to="/signin">Sign In</router-link>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import authService from '@/services/auth.service';

export default {
  data() {
    return {
      loading: false,
      message: "",
      user: {},
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/market/client");
    }
  },
  methods: {
    handleSendLink(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = this.user;
          data.callback = window.location.origin + "/reset-password";
          authService.forgotPassword(data).then(
            (res) => {
              this.loading = false;
              this.$notify({
                title: "Forgot Password",
                message: res.data.message,
                type: "warning",
              });
              // this.$router.push("/signin");
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.$notify({
                title: "Error",
                message: this.message,
                type: "error",
              });
            }
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>