import axios from "axios";
import { authHeader } from "./auth.header";
import helpers from "@/helpers/helper";
import router from "@/router";
import store from "@/store";

const API_ENDPOINT = helpers.API_ENDPOINT;
const API_URL = helpers.API_ENDPOINT;


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status === 403) {
    //   this.loading = false;
    //   this.message =
    //     (error.response &&
    //       error.response.data &&
    //       error.response.data.message) ||
    //     error.message ||
    //     error.toString();
    //   this.$notify({
    //     title: "Error",
    //     message: this.message,
    //     type: "error",
    //   })
    // }
    if (error.response === undefined) {
      console.log("Network error");
      store.dispatch("auth/logout");
      router.push("/Signin");
      return false;
    } else if (error.response.status === 401) {
      console.log("Unauthenticated");
      store.dispatch("auth/logout");
      router.push("/Signin");
      return false;
    } else if (error.response.status === 403) {
      console.log("Unauthorized to do this action");
    }
    return Promise.reject(error);
  }
);

class apiService {
  // start: company-branches
  saveClient(data) {
    if (data.id) {
      return axios.patch(API_URL + "company/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "company", data, {
        headers: authHeader(),
      });
    }
  }
  deleteClient(id) {
    return axios.delete(API_URL + "company" + id, { headers: authHeader() });
  }
  // start: company-branches
  // start: category
  saveCategory(data) {
    if (data.id) {
      return axios.put(API_URL + "blog/category/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "blog/category", data, {
        headers: authHeader(),
      });
    }
  }
  deleteCategory(id) {
    return axios.delete(API_URL + "blog/category/" + id, {
      headers: authHeader(),
    });
  }
  // start: category
  // start: blog
  addBlog(data) {
    const hasIdField = data.has("id");
    if (hasIdField) {
      const idValue = data.get("id");
      return axios.put(API_URL + "blog/posts/" + idValue, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "blog/posts", data, {
        headers: authHeader(),
      });
    }
  }
  // addBlog(data) {
  //   const hasIdField = data.has('id');
  //   if (hasIdField) {
  //     const idValue = data.get('id');
  //     return axios.put(API_URL + "blog/posts/" + idValue, data, {
  //       headers: authHeader(),
  //     })
  //     .then(response => {
  //       // Successfully updated the blog post
  //       const updatedPost = response.data.data;
  //       console.log("Updated Title:", updatedPost.attributes.title);
  //       console.log("Updated Content:", updatedPost.attributes.content);
  //       return response;
  //     })
  //     .catch(error => {
  //       // Handle errors
  //       throw error;
  //     });
  //   } else {
  //     return axios.post(API_URL + "blog/posts", data, {
  //       headers: authHeader(),
  //     })
  //     .then(response => {
  //       // Successfully added a new blog post
  //       const newPost = response.data.data;
  //       console.log("New Title:", newPost.attributes.title);
  //       console.log("New Content:", newPost.attributes.content);
  //       return response;
  //     })
  //     .catch(error => {
  //       // Handle errors
  //       throw error;
  //     });
  //   }
  // }

  addTestimony(data) {
    if (data.id) {
      return axios
        .put(API_URL + "testimonials/" + data.id, data)
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error updating testimonial:", error);
          throw error;
        });
    } else {
      const formData = new FormData();
      formData.append("company_name", data.company_name);
      formData.append("admin", data.admin);
      formData.append("rating_stars", data.rating_stars);
      formData.append("content", data.content);

      if (data.icon !== null && data.icon !== undefined) {
        formData.append("icon", data.icon);
      }

      return axios
        .post(API_URL + "testimonials", formData)
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error creating testimonial:", error);
          throw error;
        });
    }
  }

  // Delete a testimonial
  deleteTestimonial(testimonialId) {
    return axios
      .delete(API_URL + "testimonials/" + testimonialId)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
  

  deleteBlog(id) {
    console.log("Delete This post", id);
    return axios.delete(API_URL + "blog/posts/" + id, {
      headers: authHeader(),
    });
  }
  // start: blog
}

export default new apiService();
