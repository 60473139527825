import helpers from '@/helpers/helper';
import Axios from './Axios';

const API_URL = helpers.API_ENDPOINT;

class interestedService {
    getInterested(data) {
      return Axios({
        method  : 'GET',
        url     : API_URL + 'blog/interested' + '?page=' + data.page,
        data    : data,
      });
    }
    patchInterested(data) {
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'blog/interested/' + data.id,
        data    : data,
      });
    }
  }
export default new interestedService();