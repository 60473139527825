import moment from "moment" //Not yet installed

let apiEndpoints = "";
switch(process.env.VUE_APP_ENV) {
    case 'local_patrick' :
        apiEndpoints = "http://127.0.0.1/zemo-ideal-customer-platform/api/public/api/";
        break;
    case 'test' :
        apiEndpoints = "https://icptst.zemocard.com/api/";
        break;
    default :
        // apiEndpoints = "https://icpapi.zemocard.com/api/";
        apiEndpoints = "https://icptst.zemocard.com/api/";

        break;
}
const API_ENDPOINT = apiEndpoints 
const BASE_URL = "https://api.zemocard.com/";
const copyright = new Date().getFullYear() + " " + "Melin Software Solutions Ltd. All Right Reserved";
const version = "Version: 1.0.3";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); 
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
function getRandomString(length) {
    var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
}
function getInitials(str) {
    return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
}
function currencyFormat(amount, decimal = 2) {
    return Number(amount).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
    });
}
function formatNumber(value) {
    return Number(value).toLocaleString('en-US');
}
function percentile(funded_wallets, empty_wallets) {
    let percentile = (funded_wallets / empty_wallets) * 100;
    return (percentile).toLocaleString(undefined, { maximumFractionDigits: 2 });
}
function dateFormat(date) {
    return moment(date).format("Do MMM, YYYY hh:mm A");
}
function dateFormatYear(date) {
    return moment(date).format("YYYY");
}
// formatPaymentsStatus
function dateFormatD() {
    const today = new Date();
    return moment(today).format('Do MMM, YYYY hh:mm A');
}
function formatPaymentsStatus(value) {
    if (value == 0) {
        return "fails and refunded";
    } else if (value == 1) {
        return "Completed";
    } else  if (value == 2) {
        return "Rejected";
    } if (value == 3) {
        return "Reversed";
    } else {
        return "Cancelled";
    } 
}
function formatClientStatus(value) {
    switch (true) {
        case value == 0:
            return "In Active";
        case value == 1:
            return "Active";
        default:
            return "Prospect";
    }
}
function formatTaskStatus(value) {
    switch (true) {
        case value == 0:
            return "In Progress";
        case value == 1:
            return "Done";
        default:
            return "TBA";
    }
}
function formatAmount(row, col, value, index) {
    return Number(value ? value : 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
    });
}
function formatTimestamp(row, col, value, index) {
    var timestamp = Date.parse(value);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        const hour = d.getHours();
        const minute = d.getMinutes();
        if (hour === 12 && minute === 0) {
            return moment(d).format("DD/MM/YYYY") + ' ' + 'Noon';
        } else if (hour === 0 && minute === 0) {
            return moment(d).format("DD/MM/YYYY") + ' ' + 'Midnight';
        }
        else {
            return moment(d).format("DD/MM/YYYY hh:mmA");
        }
    }
}
function formatDate(data) {
    var timestamp = Date.parse(data);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        return moment(d).format("YYYY/MM/DD");
    }
}
function formatDatePayroll(row, col, value, index) {
    var timestamp = Date.parse(value);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        return moment(d).format("DD/MM/YYYY");
    }
}
function formatYear(data) {
    var timestamp = Date.parse(data);
    var d = new Date(timestamp);
    return moment(d).format("YYYY")
}
function capitalizeFirstLetter(string) {
    var initial = string.replaceAll('_', ' ')
    const fn = initial.trim().split(/\s+/);
    var name = fn[0];
    return name.charAt(0).toUpperCase() + name.slice(1);
}
function dateToday() {
    var today = new Date().toLocaleString();
    return moment(today).format("DD/MM/YYYY hh:mmA");
}
function filterDate(date) {
    var timestamp = Date.parse(date);
    if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
        return moment(d).format("YYYY-MM-DD");
    }
}
function formatMonth(value) {
    return moment(value).format("MMM YYYY");
}
function greetings(data) {
    const strfn = data;
    const fn = strfn.trim().split(/\s+/);
    return fn[0];
}
function checkfigure(value) {
    let cleanfigure = value
    return cleanfigure = cleanfigure.replace(/[^A-Za-z0-9]/g, '');
}
function formatApprovalStatus(value) {
    if (value == 1) {
        return "Approved";
    } else if (value == 2) {
        return "Rejected";
    } else if (value == 3) {
        return "Refunded";
    } else if (value === 4) {
        return "No Approval";
    } else {
        return "Pending Approval";
    }
}
function formartbBlog(value){
    if(value == 1){
        return "Public"
    }else if(value == 0){
        return "Private"
    }
}
export default {
    API_ENDPOINT,
    BASE_URL,
    copyright,
    version,
    getBase64,
    getRandomString,
    getInitials,
    currencyFormat,
    formatNumber,
    percentile,
    dateFormat,
    dateFormatYear,
    dateFormatD,
    formatPaymentsStatus,
    formatClientStatus,
    formatTaskStatus,
    formatAmount,
    formatTimestamp,
    formatDate,
    formatDatePayroll,
    formatYear,
    capitalizeFirstLetter,
    dateToday,
    filterDate,
    formatMonth,
    greetings,
    checkfigure,
    formatApprovalStatus,
    formartbBlog,
}

