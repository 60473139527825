<template>
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark"
          src="/assets/img/logo.png"
          alt="Logo"
        />
        <el-card class="loginbox">
          <el-form
            :model="user"
            size="large"
            ref="user"
            label-position="top"
            inline
            status-icon
          >
            <el-row class="p-3">
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="text-center p-1"
              >
                <h5>Sign In</h5>
                <p>Admin ICP Portal</p>
              </el-col>
              <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <img
                  class="img-fluid logo-dark"
                  src="/assets/img/logo.png"
                  alt="Logo"
                />
                <div class="sign-in">
                  <span class="or-line"></span>
                  <span class="span-sign">Sign in</span>
                </div>
              </el-col> -->
              <el-col
                style="margin-top: 10px"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
              >
                <el-form-item
                  prop="email"
                  :rules="[
                    {
                      required: true,
                      message: 'Email address is required',
                      trigger: ['blur'],
                    },
                    {
                      type: 'email',
                      message: 'Please input correct email address',
                      trigger: ['blur', 'change'],
                    },
                  ]"
                >
                  <!-- <el-input
                    v-model="user.email"
                    type="email"
                    autocomplete="off"
                    placeholder="Email"
                  /> -->
                  <el-input
                    v-model="user.email"
                    type="email"
                    autocomplete="off"
                    placeholder="Email"
                  >
                    <template #prepend>
                      <span class="text-primary me-1" style="min-width: 30px:"
                        ><i class="fa fa-envelope"></i
                      ></span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                style="margin-top: 10px"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
              >
                <el-form-item
                  prop="password"
                  :rules="[
                    {
                      required: true,
                      message: 'Password is required',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <!-- <el-input
                    v-model="user.password"
                    type="password"
                    autocomplete="off"
                    placeholder="Password"
                  /> -->
                  <el-input
                    v-model="user.password"
                    type="password"
                    autocomplete="off"
                    placeholder="Password"
                  >
                    <template #prepend>
                      <span class="text-primary me-1" style="min-width: 30px:"
                        ><i class="fa fa-lock"></i
                      ></span>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24"
                class="p-2 text-end"
              >
                <router-link to="/forgotPassword" class="forgot-link"
                  >Forgot Password?</router-link
                >
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-form-item label="">
                  <!-- plain -->
                  <el-button
                    type="primary"
                    class="w-100"
                    @click="handleLogin('user')"
                    :loading="loading"
                    >Sign in</el-button
                  ></el-form-item
                >
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div style="margin-bottom: 30px"></div>
                <!-- <div class="login-or">
                  <span class="or-line"></span>
                  <span class="span-or">or</span>
                </div> -->
                <!-- <div class="text-center dont-have">
                  Don't have an account yet?
                  <router-link to="/signup">Sign Up</router-link>
                </div> -->
              </el-col>
            </el-row>
            
          </el-form>
        </el-card>
      </div>
      <div class="text-center">
        <small>&copy; {{ $helpers.copyright }}</small
        ><br />
        <small>{{ $helpers.version }}</small>
      </div>
    </div>
  </div>
</template>

<script >
import { ref, reactive, h } from "vue";
// import AuthService from "../../services/auth_service.js";
import { ElNotification } from "element-plus";
import Axios from "@/services/Axios";

export default {
  data() {
    return {
      loading: false,
      user: {},
      message: "",
      authenticated: true,
      alertTitle:
        "Please check your email!, We've sent you an email with a verification code to make sure it's really you signing in. The code will expire in an hour.",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    this.returnUrl = this.$route.query.returnUrl || "/market/client";
    if (this.loggedIn) {
      return this.$router.push(this.returnUrl);
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
    },
    handleLogin(validate) {
      this.$refs[validate].validate((valid) => { 
        if (valid) {
          this.loading = true;
          let user = this.user;
          this.$store.dispatch("auth/signin", user).then(
            (response) => {
              Axios.defaults.headers.common.Authorization = 'Bearer '+ response.attributes.token
              this.$router.push(this.returnUrl);
              this.loading = false;
            },
            (error) => {
              console.log(error);
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              ElNotification.error({
                title: "Failed",
                message: h("n", { class: "text-warning" }, this.message),
                type: "warning",
                position: "bottom-right",
                showClose: true,
              });
              this.loading = false;
            }
          );
        } else {
          return false;
        }
      });
    },
    handleVerify(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          let user = this.user;
          this.$store.dispatch("auth/signin", user).then((response) => {
            this.authenticated = false;
            if (response.attributes.login_attempts == 10) {
              this.$notify({
                title: "Info",
                message: "Change your first time login password",
                type: "info",
              });
              this.$router.push("/settings/password");
            } else {
              if (!response.attributes.auth_code) {
                this.user = {};
                this.$notify({
                  title: "Hi " + response.attributes.name,
                  message:
                    "Welcome to Zemo! We are thrilled to have you trust our services.",
                  type: "success",
                });
                this.$router.push(this.returnUrl);
              } else {
                this.$notify({
                  title: "Error Verification",
                  message: h(
                    "n",
                    { class: "text-warning" },
                    "Hi " +
                      response.attributes.name +
                      ", You have entered WRONG verification code; Please use a NEW code we've re-sent you. The code will expire in an hour."
                  ),
                  type: "warning",
                  position: "bottom-right",
                });
                this.$store.dispatch("auth/logout");
              }
            }
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>