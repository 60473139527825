import { createRouter, createWebHistory } from "vue-router";
import Signin from "../views/auth/SignIn.vue";
import MainContainer from "@/views/layout/MainLayout.vue";
import NotFound from "../views/layout/NotFound.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
const routes = [{
    path: "/404",
    name: "NotFound",
    component: NotFound,
}, {
    path: "/signin",
    name: "Signin",
    component: Signin,
},
{
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
},
{
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
},
{
    path: '/',
    name: 'home',
    redirect: 'statistics/statistics',
    component: MainContainer,
    meta: {
        title: 'Dashboard',
        authorize: []
    },
    children: [
        {
            path: '/market/client',
            name: 'Clients',
            component: () =>
                import("@/views/market/Client.vue"),
            meta: {
                title: 'Clients',
                authorize: [],
            }
        },
        {
            path: '/market/deal/:id',
            name: 'Deals',
            component: () =>
                import("@/views/market/Deal.vue"),
            meta: {
                title: 'Deals',
                authorize: [],
            }
        },
        {
            path: '/task/tasks',
            name: 'Tasks',
            component: () =>
                import("@/views/task/Task.vue"),
            meta: {
                title: 'Tasks',
                authorize: [],
            }
        },
        {
            path: '/team/teams',
            name: 'Teams',
            component: () => import('@/views/team/Team.vue'),
            meta: {
                title: 'Teams',
                authorize: [],
            }

        },
        {
            path: '/statistics/statistics',
            name: 'Statistics',
            component: () => import('@/views/statistics/Statistics.vue'),
            meta: {
                title: 'Statistics',
                authorize: [],
            }

        },
        {
            path: '/affiliates',
            name: 'Affiliates',
            component: () => import('@/views/Affiliate/Affiliate.vue'),
            meta: {
                title: 'Affiliates',
                authorize: [],
            }

        },
        {
            path: '/affiliate-deals',
            name: 'Business',
            component: () => import('@/views/Affiliate/Business.vue'),
            meta: {
                title: 'Business',
                authorize: [],
            }

        },
        {
            path: '/blog/manage/blog',
            name: 'Blog',
            component: () => import('@/views/blog/manage/Blog.vue'),
            meta: {
                title: 'Blog',
                authorize: [],
            }

        },
        {
            path: '/blog/manage/post',
            name: 'Post',
            component: () => import('@/views/blog/manage/Post.vue'),
            meta: {
                title: 'Post',
                authorize: [],
            }

        },
        {
            path: '/blog/category',
            name: 'Category',
            component: () => import('@/views/blog/Category.vue'),
            meta: {
                title: 'Category',
                authorize: [],
            }

        },
        {
            path: '/testimonials',
            name: 'Testimonials',
            component: () => import('@/views/Testimonials/Testimonials.vue'),
            meta: {
                title: 'Testimonials',
                authorize: [],
            }

        },
        {
            path: '/contact-us',
            name: 'Contact Us',
            component: () => import('@/views/Website/ContactUs.vue'),
            meta: {
                title: 'Contact Us',
                authorize: [],
            }

        },
        {
            path: '/partner/profiles',
            name: 'Partners',
            component: () => import('@/views/Partners/Partners.vue'),
            meta: {
                title: 'Partners',
                authorize: [],
            }

        },
        {
            path: '/partners-referrals',
            name: 'PartnersContact',
            component: () => import('@/views/Partners/PartnersContact.vue'),
            meta: {
                title: 'PartnersContact',
                authorize: [],
            }

        },

        {
            path: '/new/partner',
            name: 'NewPartner',
            component: () => import('@/views/Partners/NewPartner.vue'),
            meta: {
                title: 'NewPartner',
                authorize: [],
            }

        },
        {
            path: '/interested',
            name: 'Interested',
            component: () => import('@/views/Website/Interested.vue'),
            meta: {
                title: 'Interested',
                authorize: [],
            }

        },

        // start: sales projection
        {
            path: '/sales/sales',
            name: 'Sales',
            component: () => import('@/views/sales/Sales.vue'),
            meta: {
                title: 'Sales',
                authorize: [],
            }

        },
        {
            path: '/sales/account_values',
            name: 'Account Value',
            component: () => import('@/views/sales/AccountValue.vue'),
            meta: {
                title: 'Account Value',
                authorize: [],
            }

        },
        {
            path: '/sales/sales_projection',
            name: 'Sales Projection',
            component: () => import('@/views/sales/SalesProjection.vue'),
            meta: {
                title: 'Sales Projection',
                authorize: [],
            }

        },
        {
            path: '/sales/emails_metrics',
            name: 'Email Metrics',
            component: () => import('@/views/sales/EmailMetrics.vue'),
            meta: {
                title: 'Email Metrics',
                authorize: [],
            }

        },
        // end: sales projection



    ]
},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const { authorize } = to.meta;
    const isAuthenticated = localStorage.getItem("user");
    if (authorize) {
        if (!isAuthenticated) {
            return next({ path: '/signin', query: { returnUrl: to.path } });
        }
    }
    next();
});
export default router;