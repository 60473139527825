import helpers from "@/helpers/helper";
import Axios from "./Axios";

const API_URL = helpers.API_ENDPOINT;

class teamService {
    getTeam(data) {
        return Axios({
            method  : "POST",
            url     : API_URL + 'zemo-team' + '?page=' + data.page,
            data    : data,
        })
    }
    postTeam(data) {
        return Axios({
            method  : "POST",
            url     : API_URL + 'team',
            data    : data,
        })
    }
    patchTeam(data) {
        return Axios({
            method  : "PATCH",
            url     : API_URL + 'team/' + data.id,
            data    : data,
        })
    }
    deleteTeam(data) {
        return Axios({
            method  : "DELETE",
            url     : API_URL + 'team/' + data.id,
        })
    }
}
export default new teamService();