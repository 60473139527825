import helpers from '@/helpers/helper';
import Axios from './Axios';

const API_URL = helpers.API_ENDPOINT;

class contactUsService {
    getContactUs(data) {
      return Axios({
        method  : 'GET',
        url     : API_URL + 'blog/contact-us' + '?page=' + data.page,
        data    : data,
      });
    }
    patchContactUs(data) {
      return Axios({
        method  : 'PATCH',
        url     : API_URL + 'blog/contact-us/' + data.id,
        data    : data,
      });
    }
  }
export default new contactUsService();