import emailService from "@/services/email.service";

export const email = {
    namespaced      : true,
    state           : {
        emails      : [],
        meta        : []
        // email       : {},
    },
    actions : {
        getEmails({ commit }, data) {
            return emailService.getEmails(data).then((response) => {
                commit("emails", response.data);
                return Promise.resolve(response);
            });
        },
        postEmail({ commit }, data) {
            return emailService.postEmail(data).then((response) => {
                commit("email", response.data.data);
                return Promise.resolve(response);
            });
        },
    },
    mutations   : {
        emails(state, data) {
            state.emails    = data.data;
            state.meta      = data.meta
        },
        email(state, data) {
            for (let index = 0; index < (state.emails).length; index++) {
                if(state.emails[index].id == data.id) {
                    state.emails.splice(index,1,data);
                    return;
                }
            }
            state.emails.unshift(data);
        }
    },
};