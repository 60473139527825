import authService from "../services/auth.service"

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user }

export const auth = {
    namespaced: true,

    state: initialState,

    actions: {
        signin({ commit }, user) {
            return authService.signin(user).then(
                (response) => {
                    commit("signinSuccess", response);
                    return Promise.resolve(response);
                },
                (error) => {
                    commit("loginFailure");
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            authService.logout();
            commit("logout");
        },
    },

    mutations: {
        signinSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
    },

}